import sImgS1 from '../images/service-single/img-1.jpg'
import sImgS2 from '../images/service-single/img-2.jpg'
import sImgS3 from '../images/service-single/img-3.jpg'
import sImgS4 from '../images/service-single/img-4.jpg'

import sSingleimg1 from '../images/service-single/2.jpg'
import sSingleimg2 from '../images/service-single/3.jpg'

import eduS1 from '../images/service/education/img-1.jpg'
import eduS2 from '../images/service/education/img-2.jpg'
import eduS3 from '../images/service/education/img-3.jpg'

import sS1 from '../images/service/social/img-1.jpg'
import sS2 from '../images/service/social/img-2.jpg'
import sS3 from '../images/service/social/img-3.jpg'

import business1 from '../images/service/business/img-1.jpg'
import business2 from '../images/service/business/img-2.jpg'
import business3 from '../images/service/business/img-3.jpg'

import Qlf1 from '../images/service/qualification/img-1.jpg'
import Qlf2 from '../images/service/qualification/img-2.jpg'
import Qlf3 from '../images/service/qualification/img-3.jpg'

import develop1 from '../images/service/develop/img-1.jpg'
import develop2 from '../images/service/develop/img-2.jpg'
import develop3 from '../images/service/develop/img-3.jpg'




const Services = [
    {
        Id: '1',
        sImgS:sImgS1,
        sTitle: 'Обществен форум',
        slug:'Провеждане на обществен форум',
        description:'Провеждане на широк обществен форум с възможност за пряко участие на гражданите и представители на всички сектори: бизнес, образование, спорт, обществен ред и сигурност, култура и изкуства, опазване на културното и историческо наследство, инфраструктура и благоустрояване на градската среда, неправителствените организации и представители на селата в Община Габрово.',
        icon:'',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '4',
        sImgS:sImgS4,
        sTitle: 'Бизнес и инвестиции',
        slug:'Business',
        description:'Подкрепа за перспективни инвестиции, които откриват нови работни места, позволяващи достойни доходи и кариерно развитие, вкл. развитие на онлайн платформа за популяризиране на Габрово като възможност за развитие на бизнес; включване в процеса на студентите и насърчаване на добрата практика за създаване на съвместни местни фирми между университет, преподаватели и перспективни студенти.',
        des2:'',
        des3:'',
        icon:'fahous',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '2',
        sImgS:sImgS2,
        sTitle: 'Деца и младежи',
        slug:'Деца и младежи',
        description:'Стартиране на процес за усъвършенстване работата  с деца и подобряване на условията в образователния сектор. Това включва - преглед на състоянието и условията във всички образователни институции, категорична подкрепа за решаването на проблемите им, поставяне на конкретни срокове, в които Община Габрово ще приложи мерки за подобрение, както и конкретни мерки за подобряване на качеството на храната в детските ясли, градини.',
        des2:'',
        des3:'',
        icon:'',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '3',
        sImgS:sImgS3,
        sTitle: 'Спорт и култура',
        slug:'Business-and-Industry',
        description:'Осигуряване на лесен достъп до спорт за всяко дете, създаване на програма за подкрепа за всички спортни дейности и в кратки срокове подобряване на условията. Oсигуряване на реален достъп до изкуства за всяко дете, създаване на програма за категорична подкрепа за всички школи по изкуствата и в кратки срокове създаване на Център за развитие на талантите на децата, който да е дом на изкуствата и да улеснява родителите от логистична гледна точка.',
        des2:'',
        des3:'',
        icon:'',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '4',
        sImgS:sImgS4,
        sTitle: 'Здравеопазване',
        slug:'Roads-and-Transport-Service',
        description:'Провеждане на публична среща с представители на медиците, пациентските организации и гражданите за създаване на програма с конкретни стъпки на местно ниво, насочени към ПРЕВЕНЦИЯ на здравето на хората и повишаване на КАЧЕСТВОТО на медицинската помощ - поставяне на дефибрилатори на ключови места в града;  организиране и публичен график на безплатни профилактични прегледи от лекари по кварталите и селата.',
        des2:'',
        des3:'',
        icon:'fahous',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },    
    // {
    //     Id: '6',
    //     sImgS:eduS1,
    //     sTitle: 'Graduated From Cambridge University in Education policy',
    //     slug:'Graduated-From-Cambridge',
    //     description:'Education policy involves researching and analyzing education systems, laws and governance regulations.',
    //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
    //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    //     icon:'flaticon-job',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // },
    // {
    //     Id: '7',
    //     sImgS:eduS2,
    //     sTitle: 'Finished Educational leadership From Cambridge University.',
    //     slug:'Educational-leadership',
    //     description:'This field involves learning about leadership styles, effective communication, decision-making, strategic planning.',
    //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
    //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    //     icon:'flaticon-job',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // },
    // {
    //     Id: '8',
    //     sImgS:eduS3,
    //     sTitle: 'Graduated from Oxford in Educational psychology',
    //     slug:'Educational-psychology',
    //     description:'Educational psychology is understanding how students learn, develop, and behave in educational settings.',
    //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
    //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    //     icon:'flaticon-job',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // },
    // {
    //     Id: '9',
    //     sImgS:sS1,
    //     sTitle: 'New York: Support for Womans April 2023',
    //     slug:'Support',
    //     description:'Education policy involves researching and analyzing education systems, laws and governance regulations.',
    //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
    //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    //     icon:'flaticon-job',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // },
    // {
    //     Id: '10',
    //     sImgS:sS2,
    //     sTitle: 'The Best Strategically Build Your Business Earning',
    //     slug:'Strategically',
    //     description:'This field involves learning about leadership styles, effective communication, decision-making, strategic planning.',
    //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
    //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    //     icon:'flaticon-job',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // },
    // {
    //     Id: '11',
    //     sImgS:sS3,
    //     sTitle: 'Political Commitment for Your Deportation Issue',
    //     slug:'Commitment',
    //     description:'Educational psychology is understanding how students learn, develop, and behave in educational settings.',
    //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
    //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    //     icon:'flaticon-job',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // },
    // {
    //     Id: '12',
    //     sImgS:business1,
    //     sTitle: 'We provide advanced solution growin your online',
    //     slug:'provide-advanced-solution',
    //     description:'Education policy involves researching and analyzing education systems, laws and governance regulations.',
    //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
    //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    //     icon:'flaticon-job',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // },
    // {
    //     Id: '13',
    //     sImgS:business2,
    //     sTitle: 'We provide advanced solutions growin your online business',
    //     slug:'advanced-solutions-growin',
    //     description:'This field involves learning about leadership styles, effective communication, decision-making, strategic planning.',
    //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
    //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    //     icon:'flaticon-job',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // },
    // {
    //     Id: '14',
    //     sImgS:business3,
    //     sTitle: 'Many desktop publishing packag and web page editors use.',
    //     slug:'Many-desktop-publishing',
    //     description:'Educational psychology is understanding how students learn, develop, and behave in educational settings.',
    //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
    //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    //     icon:'flaticon-job',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // },
    // {
    //     Id: '15',
    //     sImgS:Qlf1,
    //     sTitle: 'We have put together a list of our favorite art blogs that you',
    //     slug:'favorite-art-blogs',
    //     description:'Education policy involves researching and analyzing education systems, laws and governance regulations.',
    //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
    //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    //     icon:'flaticon-job',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // },
    // {
    //     Id: '16',
    //     sImgS:Qlf2,
    //     sTitle: 'A blog that inspires singers to sing right from their heart.',
    //     slug:'inspires-singers',
    //     description:'This field involves learning about leadership styles, effective communication, decision-making, strategic planning.',
    //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
    //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    //     icon:'flaticon-job',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // },
    // {
    //     Id: '17',
    //     sImgS:Qlf3,
    //     sTitle: 'We stand behind the belief that students will perform better',
    //     slug:'behind-the-belief',
    //     description:'Educational psychology is understanding how students learn, develop, and behave in educational settings.',
    //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
    //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    //     icon:'flaticon-job',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // },
    // {
    //     Id: '18',
    //     sImgS:develop1,
    //     sTitle: 'People living in rural areas are the most neglected',
    //     slug:'People-living-in-rural',
    //     description:'Education policy involves researching and analyzing education systems, laws and governance regulations.',
    //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
    //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    //     icon:'flaticon-job',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // },
    // {
    //     Id: '19',
    //     sImgS:develop2,
    //     sTitle: 'Roads are the arteries through which the economy',
    //     slug:'Roads-are',
    //     description:'This field involves learning about leadership styles, effective communication, decision-making, strategic planning.',
    //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
    //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    //     icon:'flaticon-job',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // },
    // {
    //     Id: '20',
    //     sImgS:develop3,
    //     sTitle: 'Our Scientists, Students and River Defenders come',
    //     slug:'Our-Scientists',
    //     description:'Educational psychology is understanding how students learn, develop, and behave in educational settings.',
    //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
    //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    //     icon:'flaticon-job',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // },
]    

export default Services;