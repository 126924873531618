// images
import blogImg1 from "../images/blog/img-1.jpg";
import blogImg2 from "../images/blog/img-2.jpg";
import blogImg3 from "../images/blog/img-3.jpg";

import blogSingleImg1 from "../images/blog/blog-single/img-1.jpg";
import blogSingleImg2 from "../images/blog/blog-single/img-2.jpg";
import blogSingleImg3 from "../images/blog/blog-single/img-3.jpg";
import blogSingleImg4 from "../images/blog/blog-single/img-4.jpg";
import blogSingleImg5 from "../images/blog/blog-single/img-5.jpg";



const blogs = [
    {
        id: '1',
        title: 'Обществен семинар ППДБ - Адриан Новаков на тема „Културата - градска визия"',
        slug:'support-progressive-change',
        screens: blogSingleImg1,
        description: 'Адриан Новаков',
        author: 'Anne William',
        authorTitle:'Sineor Consultant',
        create_at: '25 Sep 2023',
        blogSingleImg:blogSingleImg1, 
        comment:'35',
        blClass:'format-standard-image',
        url: 'https://www.bta.bg/bg/vote-mi2023/kampania/gabrovo/550685-za-men-e-chest-adrian-novakov-da-e-chast-ot-listata-ni-za-kandidati-za-obshtinsk'
    },
    {
        id: '2',
        title: 'Валя Балканска идва в Габрово в подкрепа на кандидата за кмет Ралица Манолова',
        slug:'Like-the-internet',
        screens: blogSingleImg2,
        description: '',
        author: '',
        authorTitle:'',
        create_at: '26 Sep 2023',
        blogSingleImg:blogSingleImg2, 
        comment:'80',
        blClass:'format-standard-image',
        url: 'https://www.bta.bg/bg/vote-mi2023/kampania/gabrovo/548564-valya-balkanska-idva-v-gabrovo-v-podkrepa-na-kandidata-za-kmet-ralitsa-manolova'
    },
    {
        id: '3',
        title: 'Срещи с кандидатите за общински съветници и Ралица Манолова - кандидат за кмет',
        slug:'Criticism-Justice',
        screens: blogSingleImg3,
        description: '',
        author: '',
        authorTitle:'',
        create_at: '',
        blogSingleImg:blogSingleImg3,
        comment:'',
        blClass:'format-standard-image',
        url: 'https://www.bta.bg/bg/vote-mi2023/kampania/gabrovo/547886-pp-db-organizira-publichni-sreshti-s-kandidatite-za-obshtinski-savetnitsi-v-gabr'
    },
    {
        id: '4',
        title: 'Дискусия на тема “За политиката отвътре” с Яна Балникова и Явор Божанков',
        slug:'Criticism-1',
        screens: blogSingleImg4,
        description: '',
        author: '',
        authorTitle:'',
        create_at: '',
        blogSingleImg:blogSingleImg4,
        comment:'',
        blClass:'format-standard-image',
        url: 'https://www.facebook.com/events/1017255316088277'
    },
    {
        id: '5',
        title: 'Една среща за промянaта и всичко, което зависи от нас с Христо Петров (Ицо Хазарта)',
        slug:'Criticism-1',
        screens: blogSingleImg5,
        description: '',
        author: '',
        authorTitle:'',
        create_at: '',
        blogSingleImg:blogSingleImg5,
        comment:'',
        blClass:'format-standard-image',
        url: 'https://www.facebook.com/events/855800842925527'
    },
];
export default blogs;