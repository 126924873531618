import React from "react";
import { Link } from 'react-router-dom'
import PDF from "../../pdf/program.pdf"

const ClickHandler = () => {
    window.scrollTo(0, 4100);
}

const Features = (props) => {
    return (
        <section className="wpo-features-area">
            <div className="container">
                <div className="features-wrap">
                    <div className="row">
                        <div className="col col-lg-3 col-md-6 col-12">
                            <div className="feature-item-wrap">
                                <div className="feature-item">
                                    <div className="icon">
                                        <i className="fi flaticon-goal"></i>
                                    </div>
                                    <div className="feature-text">
                                        <h2><Link onClick={ClickHandler} to="">Нашата визия</Link></h2>
                                    </div>
                                </div>
                                <div className="feature-item-hidden">
                                    <div className="icon">
                                        <i className="fi flaticon-goal"></i>
                                    </div>
                                    <div className="feature-text">
                                        <h2><Link onClick={ClickHandler} to="">Нашата визия</Link></h2>
                                        <p>Нашата визия е Габрово да надмине своята слава от миналото, историята да разказва за нашите постижения и да се гордеем, че сме град, изпълнен с живот и развитие!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-12">
                            <div className="feature-item-wrap">
                                <div className="feature-item">
                                    <div className="icon">
                                        <i className="fi flaticon-conference-1"></i>
                                    </div>
                                    <div className="feature-text">
                                        <h2><Link onClick={ClickHandler} to="">Нашите събития</Link></h2>
                                    </div>
                                </div>
                                <div className="feature-item-hidden">
                                    <div className="icon">
                                        <i className="fi flaticon-conference-1"></i>
                                    </div>
                                    <div className="feature-text">
                                        <h2><Link onClick={ClickHandler} to="">Нашите събития</Link></h2>
                                        <p>Следете фейсбук страницата ни, както и настоящия сайт където ще казваме информация за нашите събития.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-12">
                            <div className="feature-item-wrap">
                                <div className="feature-item">
                                    <div className="icon">
                                        <i className="fi flaticon-charity"></i>
                                    </div>
                                    <div className="feature-text">
                                        <h2><a href={PDF} without rel='noreferrer' target='_blank'>
                                        Нашата програма</a></h2>
                                    </div>
                                </div>
                                <div className="feature-item-hidden">
                                    <div className="icon">
                                        <i className="fi flaticon-charity"></i>
                                    </div>
                                    <div className="feature-text">
                                    <h2><a href={PDF} without rel='noreferrer' target='_blank'>
                                        Нашата програма</a></h2>
                                        <p>Запознайте се с нашата управленска програма.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-12">
                            <div className="feature-item-wrap">
                                <div className="feature-item">
                                    <div className="icon">
                                        <i className="fi flaticon-community"></i>
                                    </div>
                                    <div className="feature-text">
                                        <h2><Link onClick={ClickHandler} to="">Стани част от нас</Link></h2>
                                    </div>
                                </div>
                                <div className="feature-item-hidden">
                                    <div className="icon">
                                        <i className="fi flaticon-community"></i>
                                    </div>
                                    <div className="feature-text">
                                        <h2><Link onClick={ClickHandler} to="">Стани част от нас</Link></h2>
                                        <p>Попълни онлайн заявление и стани член на Промяната, ако искаш да се включиш активно в партийната кауза. Заедно можем да изградим общество със справедливи правила, в което всеки е свободен да постига мечтите си!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features;