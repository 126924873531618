import React from 'react'
import sign from '../../images/signeture.png'

const About = (props) => {
    return (
        <section className={`wpo-about-section section-padding ${props.abClass}`}>
            <div className="container">
                <div className="wpo-about-wrap">
                    <div className="row align-items-center">
                        {/* <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-img">
                                <img src={props.abimg} alt="" />
                                <div className="wpo-about-img-text">
                                    <h4>1998</h4>

                                    <div className="rotate-text">
                                        <span>W</span>
                                        <span>e</span>
                                        <span>A</span>
                                        <span>r</span>
                                        <span>e</span>
                                        <span>W</span>
                                        <span>o</span>
                                        <span>r</span>
                                        <span>k</span>
                                        <span>i</span>
                                        <span>n</span>
                                        <span>g</span>
                                        <span>F</span>
                                        <span>o</span>
                                        <span>r</span>
                                        <span>Y</span>
                                        <span>o</span>
                                        <span>u</span>
                                        <span>S</span>
                                        <span>i</span>
                                        <span>n</span>
                                        <span>c</span>
                                        <span>e</span>
                                    </div>
                                    <div className="dots">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <div className="border-shape-1"></div>
                                    <div className="border-shape-2"></div>
                                    <div className="border-shape-3"></div>
                                </div>
                                <div className="about-shape">
                                    <div className="shape-1"></div>
                                    <div className="shape-2"></div>
                                    <div className="shape-3"></div>
                                    <div className="shape-4"></div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-text" style={{marginLeft: 300, width: '100%'}}>
                                <div className="wpo-section-title">
                                    <h2>Нашата визия</h2>
                                </div>
                                <p>Нашата визия е Габрово да надмине своята слава от миналото, историята да разказва за нашите постижения и да се гордеем, че сме град, изпълнен с живот и развитие!</p>
                                <p>Стига сме гледали с тъга към миналото, нека започнем да градим по-доброто настояще и да осигурим достойно и мечтано бъдеще за децата на Габрово! </p>
                                <p>Възхищаваме се на размаха на предприемачите от миналото, но вярваме, че дори още повече може да се постигне и днес. Тук някога е имало иновативни фабрики, впечатляващи къщи, спортни игрища за работещите и техните деца, овощни и зеленчукови градини, за да се гарантира качествената храна на всички. И общото между всички това е мисълта за хората, най-доброто във всеки етап от развитието на човека. Заслужаваме го и можем да го възродим.</p>
                                <span>Заедно!</span>
                                {/* <div className="quote">
                                    <p>“We can start by taking small steps and making small changes that can have a big
                                        impact on the world.”</p>
                                </div> */}
                                {/* <div className="wpo-about-left-info">
                                    <div className="wpo-about-left-inner">
                                        <div className="wpo-about-left-text">
                                            <h5>Robert Willum</h5>
                                            <span>CEO & Founder of Manit</span>
                                        </div>
                                    </div>
                                    <div className="signeture">
                                        <img src={sign} alt="" />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;