import timg1 from '../images/team/1.png'
import timg2 from '../images/team/2.jpg'
import timg3 from '../images/team/3.jpg'
import timg4 from '../images/team/4.jpg'

import tSimg1 from '../images/team/single/img-1.png'
import tSimg2 from '../images/team/single/img-2.jpg'
import tSimg3 from '../images/team/single/img-3.jpg'
import tSimg4 from '../images/team/single/img-4.jpg'


const Teams = [
    {
        Id: '1',
        tImg:timg1,
        tSImg:tSimg1,
        slug:'Ралица Манолова',
        name:'Ралица Манолова',
        title:'Кандидат за кмет на град Габрово',
    },
    {
        Id: '2',
        tImg:timg2,
        tSImg:tSimg2,
        slug:'Иван Иванов',
        name:'Иван Иванов',
        title:'Предприемач',
    },
    {
        Id: '3',
        tImg:timg3,
        tSImg:tSimg3,
        slug:'Ивелин Стоянов',
        name:'Ивелин Стоянов',
        title:'Преподавател',
    },
    {
        Id: '4',
        tImg:timg4,
        tSImg:tSimg4,
        slug:'Борислав Гьопсалиев',
        name:'Борислав Гьопсалиев',
        title:'Адвокат',
    }
]

export default Teams;