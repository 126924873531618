import React from 'react'
import sign from '../../images/signeture.png'

const About3 = (props) => {
    return (
        <section className={`wpo-about-section section-padding ${props.abClass}`}>
            <div className="container">
                <div className="wpo-about-wrap">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-text" style={{marginLeft: 300, width: '100%'}}>
                                <div className="wpo-section-title">
                                    <h2>Нашата управленска програта</h2>
                                </div>
                                <p>
    <h2>6 Приоритета</h2>

<p>Основните приоритети очертават посоките, в които ще се развива политиката на управление. Тези приоритети са насочени към:</p>
<p></p>

Прозрачност и ефективност на управлението 

Грижа за хората и повишаване качеството на живот

Решаване на проблемите с дългосрочна визия /а не прилагане на решения  „от днес за утре“/ - ясни критерии за избор на решения, качествено изпълнение на приложените мерки

Управление – близо до хората 

Подкрепа за перспективни инвестиции, които откриват нови работни места, позволяващи достойни доходи и кариерно развитие


Реална грижа и опазване на градската среда и природата, вкл. прилагане на политика за „зелено“ поведение и начин на живот








Всеки нов мандат на управление на Община Габрово трябва да започва с реална стъпка,  осигуряваща реално участие на гражданите в управлението, а именно:
Провеждане на широк обществен форум с възможност за пряко участие на гражданите и представители на всички сектори: бизнес, образование, спорт, обществен ред и сигурност, култура и изкуства /вкл. читалища и библиотеки, опазване на културното и историческо наследство, инфраструктура и благоустрояване на градската среда, работа с децата и младежите, социални дейности, опазване на културното и историческо наследство, неправителствен сектор, екология, туризъм /вкл. културен и събитиен туризъм/, както и представители на квартални сдружения и от селата в Община Габрово.
Този форум трябва да определя основните приоритети за развитие на Общината и да е чут гласа на активното гражданство и отделните сектори. 
Общинската администрация да подготвя и представя анализи и предложения, представители на общински съвет да изразяват позиции, но форума като инструмент да е реалния диалог с всички заинтересовани страни и да взема решения. На базата на тази реална среща и съвместните изводи и насоки да се актуализират всички стратегически документи за развитие на град Габрово и всички населени места в Общината.
Форумът в последствие да приеме формат на обществен онлайн портал, който да е с лесен достъп, лесен за ползване и да дава възможност за постъпване на сигнали от граждани по сфери на дейност и публично да може да се изразява подкрепа или несъгласие по отношение на проблеми, решения и политики.








6   Водещи цели с конкретни дейности

Габрово да е истински добър град за живеене и предпочитан избор за млади хора и семейства с ясна управленска местна политика и смислени и прозрачно разпределяни стимули от целеви общински фондове

Конкретни дейности и мерки за постигане:

Подобряване на инвестиционната среда и привличане на инвеститори в перспективни сфери – с желани професии, с високо заплащане и добра среда на работа, вкл. през онлайн платформа на няколко езика и видео-популяризиране на платформата в различни канали; ясна и прозрачна система за работа с инвеститорите

Обслужване на едно гише за хора/ семейства, решили да живеят в Габрово – ясно представени стъпки за административните процедури, през които трябва да минат и пълно съдействие от отговарящ именно за това служител в администрацията

Подобряване на съществуващите стимули, свързани с подкрепа за репродуктивните възможности за семейства с проблеми и помощта при раждане на дете – организиране на допълнителна финансова подкрепа за съществено увеличаване на размерите и броя предоставена помощ

Утвърждаване на Конкурс „Млад габровски предприемач“ за лица до 35г. с финансова награда за подпомагане на бизнеса му/й. Жури - изтъкнати местни предприемачи и успели и доказали се известни български инвеститори. Проследяване на реализацията на инициативите. Прозрачност на процесите.

Разработване на нови местни общински програми:
 за подкрепа на работещите хора с малки деца
за финансиране на местни инициативи, свързани с подобряването на качеството на живот на семействата през местни фондове, създадени в партньорство с Община Габрово, бизнеса и неправителствения сектор
за доставяне на здравословна храна за деца и ученици с ясен произход и качество, вкл. прилагане на добри практики от други малки общини и развиване на собствени биоградини
за актуални услуги и подкрепа на подрастващото поколение, включваща развитие на таланти, спорт за всички, менторски програми с кариерна насоченост, НО в партньорство с местните клубове и неправителствените организации
за оптимизиране на предоставяните социални услуги за хора в нужда и прилагане на доказали се практики от страната и други части на Европа с цел по-всеобхватна помощ – вкл. медицински услуги от разстояние, периодични прегледи в малките населени места с мобилен екип, привличане на медицински университети и университети със специалности в социалната сфера като партньори



Габрово да е град с водещо иновативно образование от детската градина до университета


Конкретни дейности и мерки за постигане:

Стартиране на процес за усъвършенстване на възможностите за работа с деца и младежи и подобряване на условията в образователния сектор - това включва:
- преглед на състоянието и условията във всички образователни институции, категорична подкрепа за решаването на проблемите им, поставяне на конкретни срокове, в които Община Габрово ще приложи необходимите мерки за подобрение;
- заявяване на категорична позиция и конкретни мерки за подобряване на качеството на храната в детските ясли, градини, училища /вкл. здравословни храни в лафки и бюфети и организирано готвене във всеки обект за достъп до прясно приготвена храна, а не разнос от една точка  към всички ясли и градини/; прилагане на добри практики от други общини и създаване на местни био зеленчукови и овощни градини; представяне на ясни мерки за контрол на тези процеси, публичност на избора на доставчици и възможност за получаване на обратна връзка от родителите;
- осигуряване на реален достъп до спорт за всяко дете, създаване на програма за категорична подкрепа за всички спортни дейности и в кратки срокове подобряване на условията за тренировки във всеки един спорт; утвърждаване на график за минимум  5 срещи годишно с представителите на всички спортни дейности в общината и поемане на реален ангажимент за съобразяване с изискваното от спортните клубове;
 - създаване на възможности за безплатни обучения през ваканциите за придобиване на умения в минимум един спорт от всяко дете – по примера на Община Троян, където ВСЯКО дете има достъп до БЕЗПЛАТНИ уроци за придобиване на базови умения по плуване, каране на ски и игра на шах; сферите на спорт за Габрово ще се изберат съвместно с родителите и спортните клубове през целеви инструмент;
- осигуряване на реален достъп до изкуства за всяко дете, създаване на програма за категорична подкрепа за всички школи по изкуствата и в кратки срокове създаване на Център за развитие на талантите на децата, който да е дом на изкуствата и да улеснява родителите от логистична гледна точка; утвърждаване на график за минимум  5 срещи годишно с представителите на всички школи по изкуствата в общината и поемане на реален ангажимент за съобразяване с изискваното от тях;
 - възстановяване на инициативата „Младежка банка“, която представлява фонд за финансиране на инициативи на ученици и младежи като неформални групи с цел насърчаване на активното гражданско участие на младите хора и подобряване на средата и развитието на общността.






Подобряване качеството на образование чрез инвестиции в трансфер на добри практики от училища и детски градини за пример, обучение на учителите, подобряване на материалната база в училищата и детските градини и финансово подпомагане на дейността им, съобразно възможностите на общинския бюджет и механизмите за разпределение на държавно делегираната субсидия

Създаване на условия за обучение по „Гражданско образование“ – за важността и смисъла на гражданското участие, за правата и задълженията ни като граждани, включително и за придобиване на умения за изразяване и ораторско майсторство

Разработване и утвърждаване на дългосрочни програми за развитие в областта на културата и спорта и осигуряване на допълнително финансиране на спортните клубове, културните и други институции по ясни и прозрачни критерии на проектен принцип – вкл. надграждане над съществуващите програми за финансиране „Младежки дейности“ и „Култура“, създаване на нова програма „Достъпен спорт“.

Подпомагане и стимулиране дейността на читалищата и извънучилищните школи чрез: 
подкрепа при организиране и популяризиране на събития с местно и национално значение
подпомагане участието им в проекти за подобряване на дейността и материално-техническата база
стимулиране на активна дейност чрез механизмите на разпределение на държавната субсидия и добавяне на общински средства към годишния бюджет за читалищата; ясни и прозрачни правила за разпределяне на тези субсидии

Създаване и утвърждаване на Център за развитие на талантите на децата във всяка възраст и развитие центрове за образование във всички възрасти

Създаване на целеви фонд за допълнителна подкрепа участието на талантливи деца в състезания и награждаване на високите постижения при класиране на първо, второ или трето място в държавни и международни състезания. Подпомагане кандидатстването на спечелилите за получаване на държавните стипендии.

Утвърждаване на връзката бизнес – образование и постигане на съответствие в очакванията на фирмите по отношение на подготовката на бъдещите специалисти







Създаване на условия и подкрепа за развитие на възможностите и привлекателността на Технически университет – Габрово


Популяризиране на Габрово като образователен център с високо качество, стремеж към иновации и перспективна реализация в партньорство с Регионален иновационен център – Габрово 


Поддръжка на всички спортни бази в добро и безопасно състояние

Съобразяване на транспортните възможности  за достъп до образователните институции и подобряване на условията, където е възможно



Всяка част от града, включително крайните квартали да са поддържани, уютни и красиви и да има ефективна и лесна за използване електронна платформа, позволяваща граждански мониторинг за наблюдение и сигнали и управлението да е близко до хората; същото уважение и отношение – и към всички населени села в Общината

Конкретни дейности и мерки за постигане:

Провеждане на ежемесечни срещи с граждани, бизнес, неправителствени организации, читалища, вкл. и по квартали

Провеждане на периодични срещи в другите населени места от общината – например – първоначална среща и уговорен срок за следващите с цел прозрачност и ефективност

Ясен механизъм за подпомагане на облагородяването в кварталите и селата



Възстановяване и/или създаване на място за отдих и игра във всеки квартал и всяко населено място в Община Габрово и определяне на отговорници за поддръжката и чистотата








Създаване на електронна платформа, позволяваща граждански мониторинг за наблюдение и сигнали и управлението да е близко до хората. Това включва възможност за даване на информация и идеи за това къде има нужда от намесата/подкрепата на общината – електронна система за регистриране на проблеми в града – повредени улици, нерегламентирани места за изхвърляне на строителни отпадъци и др., идеи за развитие, предложени от гражданите и възможност за гласуване за приоритизиране на мерките, които да се предприемат


Избор и назначаване на обществен посредник – омбудсман, местен експерт, който да защитава гражданите ефективно и да осигурява достъпност и чувствителност към проблемите на хората и населените места




Габрово да е привлекателен туристически и културен център през всички сезони 

Конкретни дейности и мерки за постигане:


Популяризиране на Габрово като туристическа дестинация с цел качествен, иновативен туризъм, активно онлайн популяризиране и реални и ефективни контакти с водещи туроператори

Подкрепа за утвърждаване на разнообразни събития и множество участници

Развитие на дейностите по подготовка на Габровския карнавал като целогодишна възможност за участие и атракция за посещение

Създаване на добавена стойност към посещенията на местни обекти чрез  интерактивни елементи и адекватно поддържане на местното културно-историческо наследство

Реализиране на цялостна политика за активни и достойно подкрепени читалища с ежегодна културна програма с все по-голям обхват

Подобряване на инфраструктурата и решаване на проблема с осигуряването на вода с цел развиване на Узана като атрактивен курорт във всеки сезон, вкл. като лятна ски дестинация – с изкуствена ски писта




Анализ на работата на Туристически – информационен център - Габрово с цел постигане на реална ефективност и утвърждаването му като партньор на бизнеса в хотелиерството и ресторантьорството и на културните институции


Разработване и добавяне на нови обекти като туристически места за посещение в Община Габрово

Създаване на виртуален пътеводител за региона, който да събира на едно място необходимата за туристите информация


В Габрово да е развита грижата за хората във всяка възраст и инфраструктурата да осигурява достъпност за всеки при спазване на принципите за естетика и функционалност на градската среда и дългосрочно опазване на природата

Конкретни дейности и мерки за постигане:

Предприемане на СПЕШНИ мерки в сферата на здравеопазването:
 - провеждане на публична среща с представители на медиците, пациентските организации и гражданите за създаване на програма с конкретни стъпки на местно ниво, насочени към ПРЕВЕНЦИЯ на здравето на хората и повишаване на КАЧЕСТВОТО на медицинската помощ 
- поставяне на дефибрилатори на ключови места в града, провеждане на граждански обучения за първа помощ;  
 - организиране и публичен график на безплатни профилактични прегледи от лекари по кварталите и селата; 
 - осигуряване на достъп до лекарства, вкл. чрез вендинг машини и по селата;  
 - установяване на ред за приемане и записване при лекар чрез конкретна система и възможност за лесна обратна връзка от пациентите чрез онлайн портал; 
 - партньорство с университети, привличане на млади кадри 
 - спешно обновяване на материално-техническата база там, където е необходимо, вкл. ремонт на кабинетите по селата и оборудване за базови прегледи и първа помощ

Търсене на възможности за финансово подпомагане на обучението на дефицитни за общината специалисти срещу трайна ангажираност за заемане на работни места









Възлагане на социални услуги на външни доставчици и контрол на дейността им


Подобряване на начина на функциониране на градския транспорт и осигуряване на транспортна свързаност с всички населени места в Общината

Създаване на буферен паркинг преди зоната за платено паркиране и подобряване организацията на платените парко-места, вкл. да се осигурят безплатни парко-места за краткосрочно ползване в близост до институциите, където хората се налага да посещават с цел получаване на определени административни услуги



Габрово да има развита, мотивирана и ефективна общинска администрация, която реално подкрепя и е в услуга на граждани, фирми и неправителствени организации 

Конкретни дейности и мерки за постигане:
Утвърждаване на морален и етичен кодекс на поведение, съвместно изготвен от служители и активни граждани, с разбирането, че доброто отношение трябва да е и от двете страни

Функционален анализ на длъжности и длъжностни характеристики, разговор със служителите, оптимизиране на процесите и мотивиране на всеки да развие своя потенциал и даване на възможност за личностно и професионално развитие; създаване на среда на уважение към труда на всеки

Скъсяване на сроковете, въвеждане на процесите на работа в онлайн платформа с възможност за мониторинг от страна на гражданите – колко писма/ жалби/ заявления за услуги постъпват всеки ден и в какъв срок се обработват; видимост на забавените или неотговорени писма

Ежемесечни работни срещи за поставяне на конкретни цели на дейността на администрацията, ясно зададени реалистични срокове и измерими критерии за постигането им; вкл. редовна и прозрачна информация за постигнатото през онлайн платформа за мониторинг на дейността на администрацията, придружена с данни за заложения и оползотворен бюджет

Максимална дигитализация и разширяване обхвата на електронните услуги и лесната им достъпност

Оптимизиране на управлението на общинските предприятия, вкл. повишаване на качеството на работата на тези предприятия

Обществени поръчки с ясни критерии за качество и последващ контрол на всеки етап на изпълнение и проверка на изпълнението на предходните поръчки в 5 годишен период, изискване за коригиращи действия при необходимост и възможност

Оптимизиране на системата за поддържане на ефективен ред и сигурност чрез общински инспекторат и надграждане на структурата с повишаване на правомощията с цел ефективност на действията на Общината

</p>
                                                              
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    )
}

export default About3;